export default {
  // text: '#1a1a1a',
  // primary: '#9d8076', //'#a59b87',
  // secondary: '#e7e7e7',
  // background: '#fafafa', // #e1e1e1
  // backgroundSecondary: 'lightgrey',
  // light: '#fdfdfd',
  // dark: '#020303',

  text: '#8e5e23',
  primary: '#cc9a78',
  secondary: '#a26a47',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
